import React, { useState } from "react";

const services = [
  {
    id: 1,
    title: "شستشوی موکت و پتو",
    src: "./assets/blanketClean.jpg",
    mainContent:
      "شستشوی موکت و پتو در قالیشویی گلریز قالیشویی گلریز با ارائه خدمات شستشوی تخصصی موکت و پتو، به شما کمک می‌کند تا این اقلام بزرگ و حجیم را با دقت و کیفیت بالا شستشو دهید. با استفاده از تجهیزات پیشرفته و مواد شوینده مناسب، موکت و پتوهای شما به‌طور کامل تمیز و ضدعفونی می‌شوند.",
    desc: " موکت و پتوها از جمله اقلام خانگی هستند که به‌طور مرتب در معرض آلودگی، گرد و غبار و لکه‌ها قرار می‌گیرند. شستشوی منظم آن‌ها نه تنها به حفظ بهداشت و تمیزی محیط کمک می‌کند، بلکه به افزایش عمر این اقلام نیز منجر می‌شود. با توجه به حجم و وزن موکت و پتوها، شستشوی دستی یا خانگی آن‌ها ممکن است نتایج مطلوبی نداشته باشد و حتی باعث آسیب به بافت آن‌ها شود.",
    title1: "شستشوی خشک موکت:",
    cont1:
      "این روش برای موکت‌هایی که نیاز به شستشوی عمقی ندارند مناسب است. با استفاده از دستگاه‌های مخصوص و مواد شوینده خشک، موکت بدون نیاز به آب تمیز می‌شود و سریع خشک می‌شود.",
    title2: "شستشوی عمقی با آب و مواد شوینده:",
    cont2:
      "این روش برای موکت‌هایی که نیاز به تمیزی بیشتر دارند استفاده می‌شود. با استفاده از دستگاه‌های شستشو و مواد شوینده مناسب، موکت به‌طور کامل تمیز و ضدعفونی می‌شود. در این روش، بافت موکت نیز حفظ می‌شود.",
    title3: "شستشوی پتو با ماشین‌های صنعتی:",
    cont3:
      "شستشوی پتوها در ماشین‌های صنعتی باعث می‌شود که تمامی آلودگی‌ها و لکه‌ها از بین بروند. این ماشین‌ها با تنظیمات مناسب از هرگونه آسیب به بافت پتو جلوگیری می‌کنند.",
    extraInfoes:
      "شستشوی منظم موکت و پتو از تجمع باکتری‌ها، قارچ‌ها و عوامل آلرژی‌زا جلوگیری می‌کند و محیطی سالم و بهداشتی را فراهم می‌آورد. همچنین، شستشوی صحیح و حرفه‌ای این اقلام، باعث حفظ نرمی، رنگ و کیفیت آن‌ها می‌شود.",
    benefites:
      "در قالیشویی گلریز در قالیشویی گلریز، شستشوی موکت و پتو با استفاده از تجهیزات پیشرفته و مواد شوینده مناسب انجام می‌شود. این خدمات باعث حذف کامل آلودگی‌ها و لکه‌ها، حفظ نرمی و کیفیت بافت و افزایش عمر مفید این اقلام می‌شود. همچنین، با شستشوی حرفه‌ای در قالیشویی گلریز، از آسیب دیدن موکت و پتو جلوگیری می‌شود.",
  },
  {
    id: 2,
    title: "شستشوی فرش",
    src: "./assets/carpetClean.jpg",
    mainContent:
      "قالیشویی گلریز با استفاده از تجهیزات پیشرفته و تیم مجرب، خدمات تخصصی شستشوی فرش را ارائه می‌دهد. با بهره‌گیری از مواد شوینده مناسب و فرآیندهای شستشوی استاندارد، فرش‌های شما به‌طور کامل تمیز، ضدعفونی و با حفظ کیفیت اولیه به شما تحویل داده می‌شوند.",
    desc: "فرش‌ها به دلیل قرار گرفتن در معرض گرد و غبار، آلودگی و لکه‌ها، به‌مرور زمان کدر و آلوده می‌شوند. شستشوی منظم فرش نه تنها باعث افزایش طول عمر آن می‌شود، بلکه به حفظ بهداشت محیط زندگی و سلامت اعضای خانواده نیز کمک می‌کند. شستشوی حرفه‌ای فرش‌ها توسط قالیشویی‌های معتبر می‌تواند کیفیت و زیبایی اولیه فرش را بازگرداند.",
    title1: "شستشوی سنتی فرش:",
    cont1:
      "این روش شامل شستشوی فرش‌ها به‌صورت دستی با استفاده از آب و مواد شوینده است. اگرچه این روش همچنان در برخی مناطق محبوب است، اما ممکن است به فرش آسیب برساند و به شستشوی حرفه‌ای‌تر نیاز داشته باشد.",
    title2: "شستشوی فرش با دستگاه‌های صنعتی:",
    cont2:
      " در این روش، فرش‌ها با دستگاه‌های مخصوص و مواد شوینده باکیفیت شسته می‌شوند. این دستگاه‌ها با ترکیب آب و مواد شوینده به‌طور کامل فرش را تمیز کرده و تمامی آلودگی‌ها، گرد و غبار و لکه‌ها را از بین می‌برند.",
    title3: "شستشوی فرش‌های دستباف:",
    cont3:
      "فرش‌های دستباف به دلیل حساسیت و ارزش بالایی که دارند، نیاز به شستشوی ویژه و مراقبت‌های خاصی دارند. در شستشوی این نوع فرش‌ها از مواد شوینده ملایم و تکنیک‌های خاصی استفاده می‌شود تا به بافت و رنگ‌های آن‌ها آسیبی وارد نشود.",
    extraInfoes:
      "مزایای شستشوی حرفه‌ای فرش: شستشوی حرفه‌ای فرش باعث می‌شود که فرش‌ها تمیز، درخشان و بدون هرگونه لکه و بوی نامطبوع شوند. همچنین، با شستشوی صحیح و با دقت، عمر فرش افزایش می‌یابد و از آسیب‌های ناشی از شستشوی غیرحرفه‌ای جلوگیری می‌شود. با استفاده از شستشوی حرفه‌ای، فرش‌های شما همواره تازه و سالم باقی می‌مانند.",
    benefites:
      " شستشوی فرش در قالیشویی گلریز با استفاده از دستگاه‌های مدرن و مواد شوینده باکیفیت، به شما این اطمینان را می‌دهد که فرش‌های شما بدون هیچ‌گونه آسیب و با حفظ کیفیت، به‌طور کامل تمیز و ضدعفونی شوند. خدمات سریع، حرفه‌ای و با دقت قالیشویی گلریز، فرش‌های شما را دوباره درخشان و زیبا می‌کند.",
  },
  {
    id: 3,
    title: "خشکشویی مبلمان",
    src: "./assets/sofaClean.jpg",
    mainContent:
      "مبل‌ها به‌عنوان یکی از مهم‌ترین وسایل خانگی، به‌طور مداوم در معرض گرد و غبار، لکه‌ها و آلودگی‌ها قرار می‌گیرند. با توجه به استفاده روزانه از مبل‌ها، شستشوی منظم آن‌ها برای حفظ بهداشت و تمیزی محیط خانه ضروری است. علاوه بر این، مبل‌ها به‌مرور زمان بوی ناخوشایندی به خود می‌گیرند و نیاز به تمیز کردن حرفه‌ای پیدا می‌کنند.",
    desc: "",
    title1: "شستشوی مبل با بخار:",
    cont1:
      " یکی از روش‌های محبوب مبل‌شویی، استفاده از بخار است. بخار آب با دمای بالا به عمق پارچه مبل نفوذ کرده و تمامی لکه‌ها و آلودگی‌ها را از بین می‌برد. این روش علاوه بر تمیز کردن، مبل‌ها را ضدعفونی کرده و بوی نامطبوع آن‌ها را نیز رفع می‌کند.",
    title2: "شستشوی مبل با دستگاه‌های مکنده:",
    cont2:
      "در این روش، مواد شوینده مخصوص به عمق پارچه مبل نفوذ می‌کند و سپس با استفاده از دستگاه‌های مکنده، تمامی آلودگی‌ها و مواد شوینده از مبل خارج می‌شود. این روش باعث می‌شود که مبل‌ها سریع‌تر خشک شده و کمتر در معرض رطوبت قرار گیرند.",
    title3: "شستشوی مبل‌های چرمی:",
    cont3:
      "مبل‌های چرمی نیاز به مراقبت و شستشوی خاصی دارند. برای این نوع مبل‌ها از مواد شوینده مخصوص چرم استفاده می‌شود تا چرم مبل‌ها حفظ شده و ترک نخورد. همچنین، از مواد نرم‌کننده برای افزایش طول عمر و براقیت چرم استفاده می‌شود.",
    extraInfoes:
      "شستشوی منظم مبل‌ها باعث می‌شود که مبل‌ها نه تنها تمیز و بهداشتی باشند، بلکه طول عمر بیشتری نیز پیدا کنند. با حذف لکه‌ها و آلودگی‌ها، مبل‌های شما دوباره زیبایی و نرمی اولیه خود را به دست می‌آورند. همچنین، شستشوی حرفه‌ای مبل‌ها از آسیب دیدن پارچه یا چرم آن‌ها جلوگیری می‌کند.",
    benefites:
      "مبل‌شویی در قالیشویی گلریز به شما امکان می‌دهد تا مبل‌های خود را بدون نگرانی از آسیب دیدن، به‌طور کامل تمیز کنید. استفاده از مواد شوینده با کیفیت و تکنیک‌های پیشرفته، به حذف کامل لکه‌ها و آلودگی‌ها کمک می‌کند و مبل‌های شما دوباره نرم و تمیز می‌شوند. همچنین، خدمات سریع و با دقت بالا، تجربه‌ای رضایت‌بخش را برای شما فراهم می‌کند.",
  },
  {
    id: 4,
    title: "ترمیم و رفوگری",
    src: "./assets/repairCarpet .jpg",
    mainContent:
      "قالیشویی گلریز با بهره‌گیری از تیم متخصص و تجهیزات مدرن، خدمات رفوگری و ترمیم فرش را ارائه می‌دهد. اگر فرش شما دچار پارگی، پوسیدگی یا آسیب‌های دیگر شده است، ما می‌توانیم آن را به حالت اولیه بازگردانیم. ترمیم دقیق و حرفه‌ای، تضمین کیفیت و حفظ زیبایی فرش از جمله ویژگی‌های خدمات ماست.",
    desc: "رفوگری و ترمیم فرش به دلایل مختلفی از جمله حفظ ارزش مادی و معنوی فرش، افزایش عمر آن و بازگرداندن زیبایی اولیه، اهمیت زیادی دارد. فرش‌ها به‌ویژه فرش‌های دستباف که از ارزش هنری بالایی برخوردارند، با گذشت زمان ممکن است دچار آسیب‌هایی چون پارگی، ساییدگی یا از بین رفتن بخشی از طرح و نقش شوند. ترمیم این فرش‌ها با استفاده از روش‌های تخصصی، باعث می‌شود تا ارزش هنری و اصالت آن‌ها حفظ شود.",
    title1: "ترمیم پارگی و پوسیدگی:",
    cont1:
      "در این روش، قسمت‌های پاره شده یا پوسیده فرش با استفاده از نخ‌ها و مواد اولیه همخوان با فرش، به شکلی ماهرانه ترمیم می‌شوند. این کار نیازمند دقت و تخصص بالا است تا فرش به حالت اولیه بازگردد.",
    title2: "رفع ساییدگی‌ها:",
    cont2:
      "ساییدگی‌ها معمولاً در قسمت‌های پر استفاده فرش مانند لبه‌ها یا مرکز آن اتفاق می‌افتد. با تکنیک‌های پیشرفته، این قسمت‌ها تقویت و دوباره بازسازی می‌شوند.",
    title3: "بازسازی طرح و نقش فرش:",
    cont3:
      "یکی از مهم‌ترین بخش‌های رفوگری، بازسازی طرح‌ها و نقوش فرش است. اگر بخشی از طرح فرش از بین رفته باشد، متخصصان با دقت زیاد آن را بازسازی می‌کنند تا فرش دوباره زیبایی خود را بازیابد.",
    extraInfoes:
      "رفوگری فرش نه تنها باعث افزایش عمر مفید فرش می‌شود بلکه از لحاظ اقتصادی نیز به صرفه است. به‌جای خرید فرش جدید، ترمیم فرش فعلی می‌تواند هزینه‌های شما را به‌طور قابل توجهی کاهش دهد. همچنین، با حفظ و بازسازی فرش‌های قدیمی و دستباف، می‌توانید از ارزش هنری و تاریخی آن‌ها محافظت کنید",
    benefites:
      "رفوگری در قالیشویی گلریز به شما کمک می‌کند تا فرش‌های قدیمی و آسیب‌دیده خود را با هزینه‌ای کمتر و کیفیتی بالا ترمیم کنید. این خدمات باعث افزایش عمر فرش، حفظ زیبایی و ارزش آن می‌شود و نیاز به خرید فرش جدید را به تأخیر می‌اندازد.",
  },
];

function ServiceCard() {
  const [selectedService, setSelectedService] = useState(null);

  const handleClick = (service) => {
    if (selectedService && selectedService.id === service.id) {
      setSelectedService(null); // Deselect the service
    } else {
      setSelectedService(service); // Select the clicked service
    }
  };

  return (
    <div
      className="services-sec"
      onClick={() => (selectedService ? setSelectedService(null) : undefined)}
    >
      <div className={`container ${selectedService ? "shrink" : ""}`}>
        {selectedService ? (
          <div className="selected-service-container">
            <div className="selected-service">
              <img className="circle" src={selectedService.src} alt="" />
              <span className="service-title">{selectedService.title}</span>
              <p className="desc">{selectedService.mainContent}</p>
            </div>
            {/* New content under the selected service */}
            <div className="selected-service-content">
              <ol className="cont1">
                <p>{selectedService.desc}</p>

                <li className="number-type">
                  <b>{selectedService.title1}</b>
                  <ul>
                    <li className="dot-type">{selectedService.cont1}</li>
                  </ul>
                </li>
                <li className="number-type">
                  <b>{selectedService.title2}</b>
                  <ul>
                    <li className="dot-type">{selectedService.cont2}</li>
                  </ul>
                </li>
                <li className="number-type">
                  <b>{selectedService.title3}</b>
                  <ul>
                    <li className="dot-type">{selectedService.cont3}</li>
                  </ul>
                </li>

                <p>{selectedService.extraInfoes}</p>
              </ol>
              <ol className="cont2">
                <ul>
                  <li className="dot-type">{selectedService.benefites}</li>
                </ul>
              </ol>
            </div>
          </div>
        ) : (
          services.map((service) => (
            <div
              key={service.id}
              className="service-item"
              onClick={() => handleClick(service)}
            >
              <img className="circle" src={service.src} alt={service.title} />
              <span className="service-title">{service.title}</span>
            </div>
          ))
        )}
      </div>
    </div>
  );
}

export default ServiceCard;
