function Main() {
  return (
    <div className="main-content" id="ghalishoyi">
        <img  className="squere-img"src="./assets/main-rug.jpg" alt="rug" />
      <div className="decription-container">
        <h1 className="title">قالیشویی گلریز</h1>
        <p>
          قالیشویی گلریز با مدیریت آقای احمد خانی، با سال‌ها{" "}
          <span className="bold-content">تجربه و تخصص </span>در زمینه شستشوی
          انواع فرش‌ها، مبل‌ها و موکت‌ها، به یکی از معتبرترین و برجسته‌ترین
          مراکز قالیشویی در تهران تبدیل شده است. هدف ما در قالیشویی گلریز، ارائه
          خدماتی است که نه تنها نیازهای مشتریان را برآورده کند، بلکه از توقعات
          آن‌ها <span className="bold-content">فراتر</span> رود.
        </p>
        <button className="order-registration-btn">ثبت سفارش</button>
      </div>
    </div>
  );
}

export default Main;
