function Divider() {
  return (
    <div className="first-divider" >
      <div className="divider-sec" id="khadamat">
        <div className="div-sec">
          <div className="circle-left" ></div>
          <div className="divider"></div>
          <span className="title">خدمات قالیشویی گلریز</span>
          <div className="divider"></div>
          <div className="circle-right"></div>
        </div>
      </div>
    </div>
  );
}

export default Divider;
