import React, { useState } from "react";

function FeedbackForm() {
  const [showMessage, setShowMessage] = useState(false);
  const [formValid, setFormValid] = useState(false);

  const handleClick = (e) => {
    e.preventDefault(); // Prevent the form from submitting by default

    // Get the form inputs
    const name = document.querySelector('input[type="text"]').value.trim();
    const comment = document.querySelector("textarea").value.trim();

    // Check if the required fields are filled
    if (name && comment) {
      setFormValid(true);
      setShowMessage(true);

      // Hide the message after 3 seconds
      setTimeout(() => {
        setShowMessage(false);
      }, 3000);
    } else {
      setFormValid(false);
      alert("لطفاً تمام فیلدهای ضروری را پر کنید."); // Alert if the required fields are not filled
    }
  };

  return (
    <div className="centerizing">
      <div className="Comment-container">
        <h2 className="header-title">دیدگاهتان را بنویسید</h2>
        <form className="form">
          <div className="form-group">
            <textarea className="text-area" placeholder="دیدگاه" required />
          </div>
          <div className="form-group-row">
            <input className="input" type="text" placeholder="نام" required />
            <input className="input" type="email" placeholder="ایمیل" />
          </div>
          <button onClick={handleClick} className="order-registration-btn">
            ثبت دیدگاه
          </button>

          {showMessage && (
            <div className="message-container">
              <div className="message">نظر ثبت شد</div>
            </div>
          )}
        </form>
      </div>
    </div>
  );
}

export default FeedbackForm;
