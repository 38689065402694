// src/WhyGlariz.js
import React from "react";

const WhyGlariz = () => {
  return (
    <div className="why-glariz">
      <h2 className="title">چرا گلریز؟</h2>
      <div className="card-container" >
      <div className="card">
          <h3>رضایت مشتریان</h3>
          <p>
            هدف نهایی ما جلب رضایت شماست. تیم ما با نهایت حوصله به نیازها و
            خواسته های شما گوش میدهد و تلاش میکند تا خدماتی فراتر از توقعات شما
            ارائه دهد
          </p>
        </div>
        <div className="card">
          <h3>کیفیت شستشوی فرش</h3>
          <p>
            ما در قالیشویی گلریز به کیفیت خدمات خود افتخار میکنیم و از مواد
            شوینده با کیفیت بالا و فناوری های پیشرفته برای حفظ و بهبود بافت و
            رنگ فرش ها استفاده میکنیم
          </p>
        </div>
        <div className="card">
          <h3>تحویل سریع و به موقع</h3>
          <p>
            احترام به زمان مشتریان یکی از اولویتهای ماست. ما تعهد داریم که خدمات
            خود را در کوتاه ترین زمان ممکن و با بالاترین کیفیت ارائه دهیم
          </p>
        </div>
        
        <div className="card">
          <h3>قیمت های رقابتی</h3>
          <p>
            ارائه خدمات با بهترین کیفیت و قیمت های منصفانه و رقابتی از دیگر
            ویژگیه ای قالیشویی گلریز است
          </p>
        </div>
      </div>
    </div>
  );
};

export default WhyGlariz;
