function Locations() {
  return (
    <div className="locations-container">
      <div className="loc-card">
        <img className="loc-pic" src="./assets/Tehran1.jpg" alt="" />
        <h2>قالیشویی گلریز در غرب تهران</h2>
      </div>
      <div className="loc-card">
        <img className="loc-pic" src="./assets/Tehran2.jpg" alt="" />
        <h2>قالیشویی گلریز در شرق تهران</h2>
      </div>
      <div className="loc-card">
        <img className="loc-pic" src="./assets/Tehran4.jpg" alt="" />
        <h2>قالیشویی گلریز در شمال تهران</h2>
      </div>
      <div className="loc-card">
        <img className="loc-pic" src="./assets/Tehran3.jpg" alt="" />
        <h2>قالیشویی گلریز در جنوب تهران</h2>
      </div>
    </div>
  );
}

export default Locations;
