import "./styles/heading.css";
import "./styles/app.css";
import "./styles/main.css";
import "./styles/fonts.css";
import "./styles/divider.css";
import "./styles/servicecard.css";
import "./styles/whygolriz.css";
import "./styles/locationsdivider.css";
import "./styles/locations.css";
import "./styles/generalquestions.css";
import "./styles/questioncard.css";
import "./styles/pricecard.css";
import "./styles/pricingsec.css";
import './styles/comments.css';
import './styles/commentcards.css';
import './styles/footer.css'
import Heading from "./comps/Heading";
import Main from "./comps/Main";
import Divider from "./comps/Divider";
import ServiceCard from "./comps/ServiceCard";
import WhyGlariz from "./comps/WhyGolriz";
import DashedDivider from "./comps/LocationsDivider";
import Locations from "./comps/Locations";
import GeneralQuestions from "./comps/GeneralQuestions";
import PricingSec from "./comps/PricingSec";
import FeedbackForm from "./comps/Comments";
import CommentCards from "./comps/CommentCards";
import Footer from "./comps/Footer";
function App() {
  return (
    <div className="">
      <Heading />
      <Main />
      <Divider />
      <ServiceCard />
      <WhyGlariz />
      <DashedDivider content={'شعب قالیشویی در تهران '}/>
      <Locations />
      <GeneralQuestions />
      {/* <DashedDivider content={'قیمت خدمات قالیشویی گلریز'}/> */}
      {/* <PricingSec/> */}
      <CommentCards/>
      <FeedbackForm/>
      <Footer />
    </div>
  );
}

export default App;
