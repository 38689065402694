import { useState } from "react";
import QuestionCard from "./QuestionCard";

function GeneralQuestions() {
  const [activeIndex, setActiveIndex] = useState(null);

  const QUESTIONS = [
    {
      que: "خدمات قالیشویی گلریز شامل چه مواردی میشود؟",
      ans: "قالیشویی گلریز خدمات متنوعی شامل شستشوی حرفهای فرشهای دستباف و ماشینی، ترمیم و رفوکاری فرشهای آسیبدیده، شستشوی مبلمان و پرده، و همچنین لکهبرداری تخصصی ارائه میدهد. هدف ما ارائه بهترین خدمات با حفظ کیفیت و سلامت فرشهای شماست.",
    },
    {
      que: "فرشها چگونه شسته میشوند؟",
      ans: "در قالیشویی گلریز، فرشها با استفاده از دستگاههای پیشرفته و مواد شوینده مخصوص که برای حفظ رنگ و بافت فرش مناسب هستند، شسته میشوند. همچنین فرشها بهصورت جداگانه و با دقت بالا شسته میشوند تا هیچ گونه آسیبی به آنها وارد نشود.",
    },
    {
      que: "مدت زمان لازم برای شستشوی فرش چقدر است؟",
      ans: "معمولاً فرآیند شستشوی فرش بین 3 تا 7 روز طول میکشد. این مدت زمان بستگی به نوع فرش، میزان آلودگی و خدمات اضافی مانند ترمیم و رفوکاری دارد.",
    },
    {
      que: "آیا قالیشویی گلریز خدمات حمل و نقل فرش ارائه میدهد؟",
      ans: "بله، ما در قالیشویی گلریز خدمات حمل و نقل رایگان فرش را ارائه میدهیم. تیم ما فرشهای شما را از درب منزل تحویل گرفته و پس از شستشو و ترمیم، به شما بازمیگرداند.",
    },
    {
      que: "هزینه شستشوی فرشها چگونه محاسبه میشود؟",
      ans: "هزینه شستشوی فرشها بر اساس نوع فرش، اندازه، و خدمات اضافی مانند ترمیم و لکهبری محاسبه میشود. برای دریافت قیمت دقیق، میتوانید با ما تماس بگیرید یا از سرویس استعلام آنلاین استفاده کنید.",
    },
    {
      que: "چگونه میتوانم از سلامت و ایمنی فرش خود مطمئن شوم؟",
      ans: "قالیشویی گلریز از بهترین مواد شوینده و تکنیکهای مدرن برای شستشوی فرشها استفاده میکند. تیم ما با تجربه و تخصص لازم، از فرشهای شما مراقبت میکند تا هیچ گونه آسیبی به آنها وارد نشود.",
    },
    {
      que: "آیا قالیشویی گلریز خدمات شستشوی مبلمان و پرده نیز ارائه میدهد؟",
      ans: "بله، ما علاوه بر شستشوی فرش، خدمات شستشوی مبلمان، پرده و دیگر منسوجات خانگی را نیز ارائه میدهیم. این خدمات با همان کیفیت بالا و دقت انجام میشوند.",
    },
    {
      que: "چه نوع فرشهایی را قالیشویی گلریز میپذیرد؟",
      ans: "قالیشویی گلریز تمامی انواع فرشها از جمله فرشهای دستباف، ماشینی، گلیم، جاجیم و حتی فرشهای عتیقه و ارزشمند را میپذیرد. هر نوع فرش با توجه به جنس و ساختار آن، بهصورت اختصاصی شسته و مراقبت میشود.",
    },
  ];

  const handleCardClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <>
      <div className="general-questions" id={"soalat"}>
        <h1 className="title">سوالات متداول</h1>
        <div className="divider-line"></div>
        <h3>سوالاتی که ممکن است برای شما پیش بیاد</h3>
        <div className="question-cards-container">
          {QUESTIONS.map((q, i) => (
            <QuestionCard
              key={i}
              que={q.que}
              ans={q.ans}
              isActive={i === activeIndex}
              onClick={() => handleCardClick(i)}
            />
          ))}
        </div>
      </div>
      <span id="gheymat"></span>
    </>
  );
}

export default GeneralQuestions;
