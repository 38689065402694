function CommentCards() {
  const comments = [
    {
      name: "علی موسوی",
      text: "فرشای خونهمون خیلی کثیف شده بود، واقعاً نمیدونستم چیکار کنم. قالیشویی گلریز فرش‌ها رو مثل روز اولشون کرد. خیلی ممنونم از تیم خوبتون!",
    },
    {
      name: "سارا",
      text: "تا حالا فکر نمیکردم یه قالیشویی اینقدر خوب باشه! فرشای ما رو عالی شستند، حتی لکه‌های قدیمی رو هم از بین بردند. خیلی راضی‌ام.",
    },
    {
      name: "مهدی احمدی",
      text: "فرش دستبافم خیلی برام مهم بود و بعد از کلی تحقیق، گلریز رو انتخاب کردم. بهترین تصمیمم بود، فرش مثل روز اولش شد.",
    },
    {
      name: "نرگس جعفری",
      text: "خیلی خوشم اومد از کارشون. فرش‌ها رو به موقع آوردن و مثل جدید شدن. از همه مهم‌تر اینکه خودم هیچ کاری نکردم، همه‌چی رو خودشون انجام دادن.",
    },
    {
      name: "رضا",
      text: "قالیشویی گلریز واقعاً کارشون عالیه. فرش‌های ماشینی ما رو خیلی تمیز و مرتب تحویل دادن. اصلاً فکر نمیکردم اینقدر خوب بشه.",
    },
    {
      name: "زهرا کریمی",
      text: "فرش قدیمی خونه مادرم رو دادم به گلریز واسه ترمیم. باورم نمیشد، ولی فرش درست مثل همون روزهای اولش شده بود. عالی بود.",
    },
    {
      name: "علی",
      text: "خیلی راضی‌ام از خدمات گلریز. فرش‌های خونمون خیلی کثیف شده بود ولی بعد از شستشو، هیچ لکی روش نموند. ممنونم ازتون.",
    },
    {
      name: "فاطمه ملکی",
      text: "قالیشویی گلریز واقعاً کارش درسته. فرش‌ها رو سریع و تمیز تحویلمون دادن. حتماً دوباره ازشون استفاده میکنم.",
    },
    {
      name: "حمید رحیمی",
      text: "مبلمان خونمون دیگه رنگ و رو رفته بود. دادم به گلریز، اصلاً باورم نمیشد اینقدر تمیز و نو بشه. خیلی خوب کار کردن.",
    },
    {
      name: "مریم",
      text: "فرشای خونمون یه مدت بود که به شستشو نیاز داشتن. گلریز فرش‌ها رو خیلی تمیز و مرتب برگردوند. از کارتون خیلی راضی‌ام.",
    },
  ];
  

  return (
    <>
      <h2 className="comment-title" id="nazarat">
        نظرات
      </h2>
      <div className="cenetrizing">
        <div className="scroll-container">
          <div className="cards">
            {comments.map((comment, index) => (
              <div className="comment-card" key={index}>
                <h3 className="card-name">{comment.name}</h3>
                <p className="card-text">{comment.text}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default CommentCards;
