function Footer() {
  return (
    <div className="footer">
      <h2>همیشه حق با مشتری است</h2>

      <div className="footer-wrapper">
        <div className="addresses">
          <span className="spacer">
            <span className=".con-span">فردوس - کاشانی </span>
            <span className=".con-span">۴۴۹۶۳۲۲۱</span>
          </span>
          <span className="spacer">
            <span className=".con-span">شهران - شهر زیبا </span>
            <span className=".con-span">۴۴۹۶۳۲۲۱</span>
          </span>
          <span className="spacer">
            <span className=".con-span">اکباتان - آپادانا </span>
            <span className=".con-span">۴۴۵۶۸۸۱۰</span>
          </span>
          <span className="spacer">
            <span className=".con-span">پونک-جنت اباد </span>
            <span className=".con-span">۴۴۸۴۵۶۲۰</span>
          </span>
          <span className="spacer">
            <span className=".con-span">پیکانشهر - شهرک۲۲بهمن </span>
            <span className=".con-span">۴۴۱۸۳۱۰</span>
          </span>
          <span className="spacer">
            <span className=".con-span">خلیج فارس - مهر آباد </span>
            <span className=".con-span">۶۶۲۷۸۵۲۶</span>
          </span>
          <span className="spacer">
            <span className=".con-span">دهکده المپیک- شهرک راه آهن </span>
            <span className=".con-span">۴۴۷۱۳۲۷۷</span>
          </span>
          <span className="spacer">
            <span className=".con-span">گیشا - مرزداران </span>
            <span className=".con-span">۸۸۲۸۲۸۰۴</span>
          </span>
          <span className="spacer">
            <span className=".con-span">شهرک غرب سعادت اباد </span>
            <span className=".con-span">۲۲۳۸۱۴۷</span>
          </span>
        </div>
        <div className="about-golriz">
          <h1 className="about-title">درباره قالیشویی گلریز</h1>
          <p>
            کارخانه قالیشویی نوین، بهترین قالیشویی تهران، از سال ۱۳78 فعالیت خود
            را آغاز کرده است. این قالیشویی با بیش از نیم قرن تجربه، آماده
            خدمت‌رسانی در حوزه شستشوی فرش و خدمات قالیشویی باشد.
          </p>
        </div>
        <div className="details">
          <span className="bold">ساعات کاری</span>
          <span className="content">
            شنبه تا پنجشنبه 7الی 2 <br /> جمعه ها 7 الی 21:30
          </span>

          <span className="bold">ادرس دفتر مرکزی</span>
          <span className="content">
            خلیج فارس بعد از چهارراه ابوسعید <br />
            به سمت آزادگان کوچه یاسر
          </span>

          <span className="bold">ادرس ایمیل</span>
          <span className="content">GolrizCarpet@gmail.com</span>

          <span className="bold">شبکه های اجتماعی</span>
          <span className="content">@GolrizCarpet</span>
        </div>
      </div>
    </div>
  );
}

export default Footer;
