import React from "react";

function QuestionCard({ que, ans, isActive, onClick }) {
  return (
    <div
      className={`question-card ${isActive ? "active" : ""}`}
      onClick={onClick}
    >
      <h1>{que}</h1>

      {isActive && (
        <>
          <div className="divider"></div>
          <p className="answer">{ans}</p>
        </>
      )}
    </div>
  );
}

export default QuestionCard;
