import { useState } from "react";

function Heading() {
  const [activeSection, setActiveSection] = useState("ghalishoyi");
  const [menuOpen, setMenuOpen] = useState(false);

  function scrollTo(secId) {
    setActiveSection(secId);
    document.getElementById(secId).scrollIntoView({ behavior: "smooth" });
    setMenuOpen(false); // Close menu after click
  }

  function toggleMenu() {
    setMenuOpen((prevState) => !prevState);
  }

  return (
    <div className="header">
      <button className="contact-btn">
        <img className="btn-icon" src="./assets/Phone.svg" alt="" />
        <span className="btn-text">!تماس فوری </span>
      </button>

      <button className="hamburger-btn" onClick={toggleMenu}>
        ☰
      </button>

      <ul className={`heading-btns ${menuOpen ? "open" : ""}`}>
        <li
          onClick={() => scrollTo("nazarat")}
          className={activeSection === "nazarat" ? "activeSec" : "li"}
        >
          نظرات
        </li>
        {/* <li
          onClick={() => scrollTo("gheymat")}
          className={activeSection === "gheymat" ? "activeSec" : "li"}
        >
          قیمت
        </li> */}
        <li
          onClick={() => scrollTo("soalat")}
          className={activeSection === "soalat" ? "activeSec" : "li"}
        >
          سوالات
        </li>
        <li
          onClick={() => scrollTo("shoab")}
          className={activeSection === "shoab" ? "activeSec" : "li"}
        >
          شعب
        </li>
        <li
          onClick={() => scrollTo("khadamat")}
          className={activeSection === "khadamat" ? "activeSec" : "li"}
        >
          خدمات
        </li>
        <li
          onClick={() => scrollTo("ghalishoyi")}
          className={activeSection === "ghalishoyi" ? "activeSec" : "li"}
        >
          قالیشویی
        </li>
      </ul>
      <span className="logo-img">
        <img src="./assets/golrizLogo.svg" alt="Logo" />
      </span>
    </div>
  );
}

export default Heading;
